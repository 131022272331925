import clsx from "clsx";

const GroupedItems = ({ children, align = "between", mdCollapse = true }) => (
  <div
    className={clsx("flex gap-x-8 gap-y-8 py-6 md:flex-row", {
      "justify-between": align === "between",
      "justify-start": align === "left",
      "justify-end": align === "right",
      "flex-col": mdCollapse,
      "overflow-x-auto": !mdCollapse
    })}
  >
    {children}
  </div>
);
export default GroupedItems;
