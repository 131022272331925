/* eslint-disable tailwindcss/no-custom-classname */
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import Image from "next/image";
import { groq } from "next-sanity";
import { blockContentToPlainText } from "react-portable-text";
import { unpackBlocks, urlFor, usePreviewSubscription } from "lib/sanity";
import { getClient } from "lib/sanity.server";
import LOCATIONS from "../components/_LOCATIONS";
import { useEffect, useRef, useState } from "react";
import Hero from "components/Hero";
import GroupedItems from "components/GroupedItems";
import CategoryCard from "components/CategoryCard";
import { useRouter } from "next/router";
import RegionReportSection from "components/RegionReportSection";
import { PlacesAutocomplete } from "components/PlacesAutocomplete";
import LocationList from "components/LocationList";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Footer from "components/Footer";
import AirHealthWhite from "icons/AirHealthWhite";
import useResize from "hooks/useResize";
import analytics from "lib/analytics";
import clsx from "clsx";
import { LocaleSwitcher } from "components/LocaleSwitcher";

const BBOX = [-122.64643, 38.04203, -121.20821, 37.48464];

const highlightLocations = [
  {
    imageUrl:
      "https://cdn.sanity.io/images/swjcmrif/production/5b30a3b26a7d858ae6f0a99eee3338aa8712e5be-2160x1540.png?w=700&h=500&fit=max",
    ButtonText: () => {
      const { t } = useTranslation();
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("no2-view-near-html", {
              place: "Sutter Street, SF"
            })
          }}
        />
      );
    },
    pollutant: "no2",
    descriptionKey: "no2-splash-html",
    params:
      "?contract=san-francisco&pollutant=no2&polygonId=88283082bdfffff&placeId=ChIJR4miMZKAhYARZLWBRLsMddU"
  },
  {
    imageUrl:
      "https://cdn.sanity.io/images/swjcmrif/production/ced998f33b0bcf7caa33d0f04b16f4982cb1e7f5-2160x1540.png?w=700&h=500&fit=max",
    ButtonText: () => {
      const { t } = useTranslation();
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("pm25-view-near-html", {
              place: "Helen St, Oakland"
            })
          }}
        />
      );
    },
    color: "#00438c",
    pollutant: "pm25",
    descriptionKey: "pm25-splash-html",
    params:
      "?contract=alameda&pollutant=pm_2.5&polygonId=882830813bfffff&placeId=ChIJ98LyaRZ-hYAR-WM_P5xU4-U"
  },
  {
    imageUrl:
      "https://cdn.sanity.io/images/swjcmrif/production/2c29b0363c8470a8d689aca552095a78708c3fa8-2160x1540.png?w=700&h=500&fit=max",
    ButtonText: () => {
      const { t } = useTranslation();
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: t("co2-view-near-html", {
              place: "N 11th St, San Jose"
            })
          }}
        />
      );
    },
    pollutant: "co2",
    descriptionKey: "co2-splash-html",
    params:
      "?contract=santa-clara&pollutant=co2&polygonId=882834716bfffff&placeId=ChIJ72w1B4_Mj4ARhyoUhNvsqHU"
  }
];

const pageQuery = groq`
*[!(_id in path('drafts.**')) && _type == "reportCollection" && __i18n_lang == $locale][0] {
  _id,
  name,
  title,
  subtitle,
  cta,
  summary_title,
  summary,
  summary_image,
  heading,
  subheading,
  bay_area_cover,
  reports[]->{ _id, name, title, summary, "slug": slug.current, },
  cover,
  sharing_image,
}
`;

const singleStoryQuery = groq`
  *[!(_id in path('drafts.**')) && _id match "*"+$id+"*" && __i18n_lang == $locale][0] {
    _id, title, titleShort, summary, cta, content, cover, pollutant, slug, category, locationPagePosition
  }
`;

const methodologyQuery = groq`
  *[!(_id in path('drafts.**')) && _type == "report" && __i18n_lang == $locale && slug.current == "sfba"][0] {
    methodology->{
      slug,
      subject,
      description,
      methodologyCategories[0..3]->{ 
        _id,
        subject,
        summary,
        image,
        slug,
      },
    },
  }
`;

export default function Home({ data, preview, location }) {
  const { t } = useTranslation();
  const scrollRef = useRef();
  const mdScrollRef = useRef();
  const router = useRouter();
  const mainPageRef = useRef();

  const { height } = useResize(mainPageRef);
  const { subject, description, methodologyCategories } =
    data?.methodology?.methodology;

  const { data: addressLookupStory } = usePreviewSubscription(
    singleStoryQuery,
    {
      initialData: data?.addressLookupStory,
      enabled: preview && data?.addressLookupStory.title
    }
  );

  const [screenWidth, setScreenWidth] = useState();
  const [screenHeight, setScreenHeight] = useState();
  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, [setScreenWidth]);

  const handleChevronClick = () => {
    if (scrollRef.current?.getBoundingClientRect()?.y) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
      return;
    }
    mdScrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  const handleSelectedPlace = place => {
    const placeId = place?.properties?.placeId;
    router.push(
      `/bayarea${
        router.locale === "en-US" ? `` : `/${router.locale}`
      }?placeId=${placeId}`
    );
    analytics.track("Click on address on splash page", {
      component: "Splash page",
      route: router.basePath,
      locale: router.locale,
      placeId: placeId,
      county: router?.query?.contract,
      pollutant: router?.query?.pollutant,
      address: place?.properties?.address
    });
  };

  const { data: page } = usePreviewSubscription(pageQuery, {
    initialData: data?.page,
    enabled: preview && data?.page?.length
  });

  const { cover, heading, subheading, bay_area_cover } = page;

  return (
    <>
      <Head>
        <meta name="robots" content="index, follow" />
        <title>{t("title")}</title>
      </Head>
      <main className="bg-gradient-to-b from-white to-community-200 scroll-smooth">
        <section
          className="flex relative justify-center p-5 w-full h-fit"
          ref={mainPageRef}
        >
          <div className="absolute top-10 left-10 z-50">
            <AirHealthWhite size={screenWidth < 400 ? 175 : 256} />
          </div>

          <div className="absolute top-10 right-10 z-50">
            <LocaleSwitcher style="light" />
          </div>

          <section
            className={clsx(
              "flex relative z-40 flex-col px-8 w-full max-w-5xl xl:mx-auto",
              { "md:mt-24 md:h-full": height < 740 },
              { "md:justify-center": height >= 740 }
            )}
          >
            <div className="mt-32 text-white">
              <div className="w-full">
                <h1 className="font-serif text-xl font-extrabold tracking-wide text-center text-white sm:mt-2 sm:text-3xl lg:mt-4 lg:text-4xl">
                  {blockContentToPlainText(addressLookupStory.cta)}
                </h1>
              </div>
            </div>
            <div className="mt-6">
              <div className="mx-auto mt-6 md:w-96">
                <PlacesAutocomplete
                  type="splash"
                  setSelectedPlace={handleSelectedPlace}
                  requestOptions={{
                    bounds: {
                      east: BBOX[2],
                      west: BBOX[0],
                      north: BBOX[1],
                      south: BBOX[3]
                    }
                  }}
                />
              </div>
            </div>
            <div className="hidden pb-6 md:block md:mt-5 lg:mt-10 xl:mt-26">
              <h2 className="tracking-wide text-white sm:mt-4 lg:mt-6">
                {t("splash-explore")}
              </h2>
              <LocationList locations={highlightLocations} />
            </div>
            <div className="flex bottom-12 z-50 justify-center mt-12 md:hidden">
              <button
                type="button"
                className="inline-flex items-center p-3 text-white bg-public-700/70 focus:bg-public-700/70 rounded-full border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm"
                onClick={handleChevronClick}
                aria-label={t("scroll-to-explore-tool")}
              >
                <HiOutlineChevronDoubleDown
                  className="w-12 h-12"
                  aria-hidden="true"
                />
              </button>
            </div>
          </section>
          {cover && (
            <Image
              src={urlFor(cover).quality(100).width(2000).height(1500).url()}
              alt="map of bay area"
              layout="fill"
              objectFit="cover"
              priority
            />
          )}
          <div
            className={clsx(
              "hidden absolute z-50 md:block md:right-12",
              screenHeight < 500 ? "bottom-1/2" : "bottom-16"
            )}
          >
            <button
              type="button"
              className="inline-flex items-center p-3 text-white bg-public-700/70 focus:bg-public-700/70 rounded-full border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-sm"
              onClick={handleChevronClick}
              aria-label={t("scroll-to-explore-tool")}
            >
              <HiOutlineChevronDoubleDown
                className="w-12 h-12"
                aria-hidden="true"
              />
            </button>
          </div>
        </section>
        <section
          className="flex flex-col justify-center px-8 mx-auto mt-6 w-full max-w-md md:hidden xl:mx-auto"
          ref={scrollRef}
        >
          <h1 className="text-sm font-bold tracking-wide text-gray-dark">
            {t("splash-explore")}
          </h1>
          <LocationList locations={highlightLocations} />
        </section>

        <section
          className="flex flex-col justify-center px-8 w-full max-w-7xl xl:mx-auto"
          ref={mdScrollRef}
        >
          <RegionReportSection
            image={bay_area_cover}
            heading={heading}
            subheading={subheading}
            reports={[]}
            location={"bay"}
            LOCATIONS={LOCATIONS}
            slug="bayarea"
          />
        </section>

        <section className="" id="methodology">
          <div className="flex flex-col flex-1 py-10 px-10 mx-auto w-full max-w-7xl antialiased">
            <Hero
              title={blockContentToPlainText(subject)}
              summary={blockContentToPlainText(description)}
              link={
                router.locale === "en-US"
                  ? `/bayarea/methodology`
                  : `/bayarea/${router.locale}/methodology/`
              }
              buttonText={t("explore-methodology")}
              onButtonClick={() => {
                analytics.track(
                  "Click on methodology from button on splash page",
                  {
                    component: "Splash page",
                    route: router.basePath,
                    locale: router.locale
                  }
                );
                router.push(
                  router.locale === "en-US"
                    ? `/bayarea/methodology`
                    : `/bayarea/${router.locale}/methodology`
                );
              }}
            />
            <GroupedItems>
              {methodologyCategories &&
                methodologyCategories.map(category => {
                  const { subject, summary, image, _id } =
                    unpackBlocks(category);
                  return (
                    <CategoryCard
                      key={_id}
                      id={_id}
                      title={subject}
                      image={
                        <Image
                          src={urlFor(image).width(685).url()}
                          alt=""
                          width={685}
                          height={340}
                          quality={100}
                          priority
                        />
                      }
                      summary={summary}
                      onClick={() => {
                        analytics.track(
                          "Click on methodology from image on splash page",
                          {
                            component: "Splash page",
                            route: router.basePath,
                            locale: router.locale
                          }
                        );
                        router.push(
                          router.locale === "en-US"
                            ? `/bayarea/methodology`
                            : `/bayarea/${router.locale}/methodology/`
                        );
                      }}
                    />
                  );
                })}
            </GroupedItems>
          </div>
        </section>
        <Footer
          LOCATIONS={LOCATIONS}
          location={location}
          mailingList={false}
          sharing={false}
        />
      </main>
    </>
  );
}

export async function getStaticProps({ locale, preview = false }) {
  const location = "ca";
  const page = await getClient(preview).fetch(pageQuery, {
    locale
  });
  const addressLookupStory = await getClient(preview).fetch(singleStoryQuery, {
    id: LOCATIONS[location].ADDRESS_LOOKUP_STORY,
    locale
  });
  const methodology = await getClient(preview).fetch(methodologyQuery, {
    locale
  });
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
      preview,
      data: { page, addressLookupStory, location, methodology }
    }
  };
}
