import React from "react";
import PropTypes from "prop-types";

const MapPin = props => {
  const { color, size, ...otherProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1118 0z"></path>
      <path d="M12 13a3 3 0 100-6 3 3 0 000 6z" fill="white"></path>
    </svg>
  );
};

MapPin.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MapPin.defaultProps = {
  color: "currentColor",
  size: "24"
};

export default MapPin;
