import SUGGESTED_ADDRESSES from "../data/suggestedAddresses.json";
const LOCATIONS = {
  ca: {
    CHARTS_AND_TABLES: [],
    ADDRESS_LOOKUP_STORY: "5a5f1cd6-d2e6-44f8-957b-011df71e51b5",
    SUGGESTED_ADDRESSES
  }
};

export default LOCATIONS;
