import { useEffect, useState } from "react";

const useResize = myRef => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    };

    window.addEventListener("resize", handleResize);

    if (width !== myRef?.current?.offsetWidth) {
      setWidth(myRef?.current?.offsetWidth);
    }

    if (height !== myRef?.current?.offsetHeight) {
      setHeight(myRef?.current?.offsetHeight);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return { width, height };
};

export default useResize;
