import ArrowRight from "icons/arrow-right";

const Hero = ({
  title,
  summary,
  introductoryText,
  link,
  buttonText,
  onButtonClick
}) => {
  return (
    <div className="relative text-left">
      <div className="flex flex-col py-12 md:grid md:grid-flow-col md:gap-6">
        <div>
          {title && (
            <h1 className="block px-0 text-left capitalize text-h3">{title}</h1>
          )}
          {summary && (
            <h2 className="mt-4 mb-6 max-w-4xl font-sans text-left text-intro">
              {summary}
            </h2>
          )}
          {introductoryText && (
            <h2 className="mt-4 mb-6 max-w-4xl font-sans text-base text-left">
              {introductoryText}
            </h2>
          )}
        </div>
        {link && (
          <div className="grid place-content-start md:place-content-center">
            <button
              className="p-3 font-bold text-white hover:bg-community-600 bg-gradient-to-b from-community-500 to-community-600 rounded border-none focus:ring cursor-pointer"
              onClick={onButtonClick}
              aria-label={buttonText}
            >
              {buttonText} <ArrowRight className="inline" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
