import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";
import { useTranslation } from "next-i18next";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from "use-places-autocomplete";
import { Combobox } from "@headlessui/react";
import { useRouter } from "next/router";
import clsx from "clsx";
import Check from "icons/check";
import ChevronsUp from "icons/chevrons-up";
import MapPin from "icons/map-pin";
import { getShortAddressFromAddressComponents } from "lib";
import analytics from "lib/analytics";
import X from "icons/x";

export const PlacesAutocomplete = ({
  requestOptions = {},
  setSelectedPlace,
  setSelectedPlaceReportPage,
  type = "",
  shouldClearAddressInput,
  setShouldClearAddressInput
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({ requestOptions, debounce: 300 });

  const handleInput = e => {
    setValue(e.target.value);
  };

  const handleSelect = value => {
    const placeName = data?.[0]?.structured_formatting?.main_text;
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(value, false);
    clearSuggestions();
    const place = { type: "Feature", properties: { name: placeName } };

    // Get latitude and longitude via utility functions
    getGeocode({ address: value })
      .then(results => {
        place.properties.address = results[0]?.formatted_address;
        place.properties.placeId = results[0]?.place_id;
        place.properties.shortAddress = getShortAddressFromAddressComponents(
          results[0]?.address_components
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        place.geometry = { type: "Point", coordinates: [lng, lat] };
        setSelectedPlace(place);

        analytics.track("Look up an Address", {
          component: "Address Lookup",
          route: router.basePath,
          locale: router.locale,
          county: router?.query?.contract,
          pollutant: router?.query?.pollutant,
          address: place?.properties?.address,
          mode: type
        });

        if (setSelectedPlaceReportPage) {
          setSelectedPlaceReportPage(true);
        }
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  };

  const clearValue = () => {
    setValue("", false);
  };

  useEffect(() => {
    if (shouldClearAddressInput && !!setShouldClearAddressInput) {
      setValue("", false);
      setShouldClearAddressInput(false);
    }
  }, [shouldClearAddressInput, setShouldClearAddressInput, setValue]);

  return (
    <Combobox as="div" value={value} onChange={handleSelect} className="w-full">
      {({ open }) => (
        <div className="relative z-[60] w-full">
          <Combobox.Input
            className="py-1 pl-8 min-w-full max-w-full h-10 text-ellipsis bg-white rounded-sm border border-community-300 focus:border-community-500 focus:outline-none focus:ring-1 focus:ring-community-600 shadow-sm sm:text-sm md:py-2 md:pr-7"
            onChange={handleInput}
            displayValue={item =>
              item?.structured_formatting?.main_text || item
            }
            disabled={!ready}
            placeholder={t("address-lookup-placeholder")}
            autoComplete="off"
          />
          {open && value.length > 0 && data.length > 0 && status === "OK" && (
            <Combobox.Button className="flex absolute inset-y-0 right-0 items-center px-2 rounded-r-sm focus:outline-none">
              <>
                <ChevronsUp
                  className="w-5 h-5 text-community-500"
                  aria-hidden="true"
                />
              </>
            </Combobox.Button>
          )}
          {!open && value.length > 0 && (
            <Combobox.Button
              className="flex absolute inset-y-0 right-0 items-center px-2 rounded-r-sm focus:outline-none"
              onClick={() => clearValue()}
            >
              <>
                <X className="w-5 h-5 text-community-500" aria-hidden="true" />
              </>
            </Combobox.Button>
          )}
          <Combobox.Button
            className="flex absolute inset-y-0 left-0 items-center px-2 rounded-r-sm focus:outline-none"
            aria-label={t("address-lookup-placeholder")}
          >
            <>
              <MapPin className="w-5 h-5 text-yellow" aria-hidden="true" />
            </>
          </Combobox.Button>
          {status === "OK" && (
            <Combobox.Options className="overflow-auto absolute z-50 py-1 mt-1 w-full max-h-60 text-base bg-white rounded-sm focus:outline-none ring-1 ring-community-300 shadow-md sm:text-sm">
              {data.map(item => (
                <Combobox.Option
                  key={item.place_id}
                  value={item.description}
                  className={({ active }) =>
                    clsx("relative py-2 pr-9 pl-3 cursor-default select-none", {
                      "bg-community-300": active
                    })
                  }
                >
                  {({ selected }) => (
                    <>
                      <div className="flex">
                        <span
                          className={clsx(
                            "text-public-700 truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {item.structured_formatting.main_text}
                        </span>
                        <span className="ml-2 text-pro-500 truncate">
                          {item.structured_formatting.secondary_text}
                        </span>
                      </div>

                      {selected && (
                        <span className="flex absolute inset-y-0 right-0 items-center pr-4 text-community-600">
                          <Check className="w-5 h-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      )}
    </Combobox>
  );
};
