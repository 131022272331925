import clsx from "clsx";

const CategoryCard = ({
  title,
  image,
  summary,
  onClick,
  activeCategory,
  id
}) => {
  return (
    <button
      className={clsx(
        "hover:z-10 flex-1 p-10 m-1 bg-white/80 hover:bg-white/[0.85] rounded-sm border border-community-300 hover:border-community-400 shadow-sm hover:shadow-md transition hover:transition-all duration-200 ease-in-out hover:scale-105 hover:cursor-pointer",
        {
          "border border-community-500": activeCategory && activeCategory === id
        }
      )}
      onClick={onClick}
    >
      {image}
      <h3 className="my-3 text-1.5xl font-bold text-left">{title}</h3>
      <p className="text-left">{summary}</p>
    </button>
  );
};

export default CategoryCard;
