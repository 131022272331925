import Image from "next/image";
import { useRouter } from "next/router";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import PortableText from "components/PortableText";
import { blockContentToPlainText } from "react-portable-text";
import { urlFor } from "lib/sanity";
import analytics from "lib/analytics";
import ArrowRight from "icons/arrow-right";

const RegionReportSection = ({
  image,
  heading,
  subheading,
  reports,
  location,
  LOCATIONS,
  slug = null,
  showImage = true
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div className={clsx({ "mt-20": showImage })}>
      <div className="flex flex-col gap-y-8 justify-between mb-8 md:flex-row md:items-center">
        <span>
          <h2 className="text-left capitalize text-h3">
            {blockContentToPlainText(heading)}
          </h2>
          {subheading && location && LOCATIONS && (
            <PortableText
              content={subheading}
              location={location}
              LOCATIONS={LOCATIONS}
            />
          )}
        </span>
        {slug && (
          <div className="text-left lg:text-right">
            <Link
              href={`/${slug || ""}${
                router.locale === "en-US" ? `` : `/${router.locale}`
              }`}
            >
              <a
                className="p-3 font-bold text-white hover:bg-community-600 bg-gradient-to-b from-community-500 to-community-600 rounded border-none"
                onClick={() =>
                  analytics.track("Click Explore Area Overview Button", {
                    route: router.pathname,
                    locale: router.locale
                  })
                }
              >
                {t("explore-tool")}
                <ArrowRight className="inline" />
              </a>
            </Link>
          </div>
        )}
      </div>

      {showImage && (
        <div
          className={clsx("group relative transition-transform duration-200", {
            "hover:scale-[1.025]": slug
          })}
        >
          <Image
            src={urlFor(image).width(1440).url()}
            alt="map of bay area"
            width={1440}
            height={800}
          />
          {slug && (
            <Link
              href={`/${slug || ""}${
                router.locale === "en-US" ? `` : `/${router.locale}`
              }`}
            >
              <a
                className="flex absolute inset-0 z-10 flex-col justify-center items-center text-center bg-gradient-to-b from-public-500/0 hover:from-public-500/80 to-community-400/0 hover:to-community-500/80 hover:shadow-md duration-200"
                onClick={() =>
                  analytics.track(
                    `Click to ${slug} region wide report from hover over image`,
                    {
                      route: router.pathname,
                      locale: router.locale
                    }
                  )
                }
              >
                <span className="flex z-50 gap-2 items-center py-2 px-4 font-sans text-lg text-community-500 group-hover:text-community-600 bg-white/[0.95] rounded-sm border-2 border-community-500 group-hover:border-community-600">
                  <p className="font-bold">{t("regionwide-tool")}</p>
                  <ArrowRight className="inline" />
                </span>
              </a>
            </Link>
          )}
        </div>
      )}

      {/* TODO: remove if no longer needed */}
      {!!reports.length && (
        <div className="flex flex-col gap-9 justify-between my-14 max-w-full xl:flex-row">
          {reports.map(report => (
            <Link href={`${router.asPath}/ca/${report.slug}`} key={report._id}>
              <a
                className="group flex flex-col justify-between p-10 bg-white/80 hover:bg-white/[0.85] rounded-sm border border-community-300 hover:border-community-400 shadow-sm hover:shadow-md transition hover:transition-all duration-200 ease-in-out hover:scale-105 xl:w-[23.4rem] xl:h-[32rem]"
                onClick={() => {
                  analytics.track(`Explore Report -> /ca/${report.slug}`, {
                    route: router.pathname,
                    locale: router.locale
                  });
                }}
              >
                <span>
                  <h2 className="font-bold leading-tight text-h4">
                    {report.name}
                  </h2>
                  <div className="mt-3 min-w-full prose">
                    {report?.summary?.length && (
                      <PortableText
                        content={report.summary}
                        location={location}
                        LOCATIONS={LOCATIONS}
                      />
                    )}
                  </div>
                </span>

                <div className="flex justify-between py-2 px-3 max-w-[23.4rem] text-white group-hover:text-community-600 bg-gradient-to-b from-community-500 to-community-600 rounded-sm border-2 border-community-500 group-hover:border-community-600 border-none">
                  <div className="font-semibold leading-relaxed">
                    Explore Report
                  </div>
                  <ArrowRight></ArrowRight>
                </div>
              </a>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default RegionReportSection;
