import Image from "next/image";
import ArrowRight from "icons/arrow-right";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

// TODO: is the class "aspect-w-7 aspect-h-5" being used here? Looks like it's not a Tailwind class
export default function LocationList({ locations }) {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <ul
      role="list"
      className="grid grid-cols-1 gap-x-4 gap-y-8 mt-6 sm:gap-x-6 md:grid-cols-3 xl:gap-x-8"
    >
      {locations.map((location, key) => (
        <li key={key} className="relative">
          <div>
            <a
              href={`${
                router.locale === "en-US"
                  ? `/bayarea`
                  : `/bayarea/${router.locale}`
              }${location.params}`}
              className="group block overflow-hidden w-full bg-gray-100 focus-within:bg-community-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 shadow-lg hover:shadow-xl transition hover:transition-all duration-200 ease-in-out hover:scale-105"
            >
              <div className="aspect-w-7 aspect-h-5">
                <div
                  className={clsx(
                    "hidden group-hover:block z-50 w-full h-full text-white cursor-pointer",
                    {
                      "bg-no2Dark/90": location.pollutant === "no2",
                      "bg-pm25Dark/90": location.pollutant === "pm25",
                      "bg-co2Dark/90": location.pollutant === "co2"
                    }
                  )}
                >
                  <span className="absolute bottom-0 p-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t(location.descriptionKey)
                      }}
                    />
                  </span>
                </div>
                <Image
                  src={location.imageUrl}
                  alt=""
                  layout="fill"
                  objectFit="cover"
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">
                    View details for {location.imageUrl}
                  </span>
                </button>
              </div>
              <div className="flex justify-center items-center p-3 w-full h-16 bg-community-200">
                <span className="flex justify-between py-2 px-3 w-full max-w-[23.4rem] text-white group-hover:bg-community-600 bg-gradient-to-b from-community-500 to-community-600 rounded border-none">
                  <div className="text-xs font-semibold leading-relaxed lg:text-sm">
                    <location.ButtonText />
                  </div>
                  <ArrowRight></ArrowRight>
                </span>
              </div>
            </a>
          </div>
        </li>
      ))}
    </ul>
  );
}
